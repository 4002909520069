import { createToast } from '../toasts.platform';
import { exportFiles } from './export';

export default async function exportUploadedFiles() {
  await exportFiles();
  createToast({
    content: 'Download requested, you will receive an email with the link to download.',
    category: 'success',
  });
}
