import { createToast } from '../toasts.platform';
import { exportDocuments } from './export';

export default async function exportDocumentsToFile() {
  const csv = await exportDocuments();
  const link = document.createElement('a');
  const file = new Blob([csv], { type: 'text/csv' });
  link.href = URL.createObjectURL(file);
  link.download = 'export.csv';
  link.click();
  URL.revokeObjectURL(link.href);
  createToast({ content: 'CSV ready to download', category: 'success' });
}
