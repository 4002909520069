import { createToast } from '../toasts.platform';
import { exportOPML } from './export';

export default async function exportOPMLToFile() {
  const xml = await exportOPML();
  if (xml) {
    const link = document.createElement('a');
    const file = new Blob([xml], { type: 'text/xml' });
    link.href = URL.createObjectURL(file);
    link.download = 'Reader_Feeds.opml';
    link.click();
    URL.revokeObjectURL(link.href);
    createToast({
      content: 'Starting download of OPML file',
      category: 'success',
    });
  } else {
    createToast({
      content: 'No feeds to export',
      category: 'warning',
    });
  }
}
